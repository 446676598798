@use "color";

.bank-card {
  box-shadow: 0 0 2px color.$shadow, 0 1px 2px color.$shadow;
  transition: all 0.2s ease-in;

  &:hover {
    box-shadow: 0 2px 2px color.$accent;
    cursor: pointer;
    opacity: 0.8;
  }

  .bank-name {
    color: color.$text;
    font-size: 1.1em;
  }

  .bank-location {
    color: lighten(black, 60%);
    font-size: 0.9em;
  }

  .bank-card-actions {
    border-top: 1px solid color.$background;
    padding-top: 1em;
  }

  .bank-card-button {
    align-items: center;
    display: flex;
    padding: 0;

    &.button-primary {
      color: color.$primary;
    }

    &.button-error {
      color: color.$error;
    }

    i {
      line-height: 1;
      margin-right: 4px;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}